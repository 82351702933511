<template>
  <div>
    <nav
      v-if="showMenu"
      tabindex="-1"
      class="navbar is-dark is-fixed-top is-vcentered"
      role="navigation"
      aria-label="main navigation"
      @focusout="isActive = false"
    >
      <div class="navbar-brand">
        <template v-if="displayWidth > 599 && !isLoggedIn">
          <RouterLink class="navbar-item btn-golstats" :to="{ name: 'home' }">
            <img src="/images/logo_golstats_horizontal_white.svg" alt="GolStats Logo" />
          </RouterLink>
        </template>
        <template v-else>
          <template v-if="isLoggedIn">
            <RouterLink class="navbar-item btn-golstats" :to="{ name: 'home' }">
              <img src="/images/logo_golstats_horizontal_white.svg" alt="GolStats Logo" />
            </RouterLink>
          </template>
        </template>
        <!--        <div>{{showMenuItems}}</div>-->
        <div @click="SET_MENU_DRAWER()" class="hamburger">
          <div class="navbar-menu-container">
            <a
              v-if="!isLoggedIn && displayWidth < 1210"
              class="navbar-item btn-create-account menu-button"
              @click.stop="onClickRegister"
            >
              CREAR CUENTA PREMIUM
              <div>
                <div>$ <span class="amount">99</span> al mes</div>
              </div>
            </a>
            <a id="test" role="button" class="navbar-burger burger" aria-label="menmargin-right: unset;">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <div class="menu-text">MENU</div>
            </a>
          </div>
        </div>
      </div>

      <div v-if="showMenuItems" id="current-items" class="navbar-menu" :class="isActive ? 'is-active' : ''">
        <div class="navbar-start">
          <a class="navbar-item btn-tips-golstats" @click="goTo(home)" :class="isHome ? 'selected-page' : ''">
            TIPS DE GOLSTATS
          </a>
          <a class="navbar-item btn-partidos" @click="goTo(matches)" :class="isMatches ? 'selected-page' : ''">
            PARTIDOS
          </a>
          <a class="navbar-item btn-contactanos" @click="goTo(contact)" :class="isContact ? 'selected-page' : ''"
            >CONTÁCTANOS</a
          >
        </div>
        <div class="navbar-end">
          <template v-if="isLoggedIn">
            <div @click="goTo(paymentMethods)">
              <a id="navbar-burger" role="button" class="navbar-burger burger" aria-label="menmargin-right: unset;">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <div class="menu-text">MENU</div>
              </a>
            </div>
          </template>
          <template v-else-if="displayWidth > 1209">
            <a class="navbar-item btn-create-account" @click="onClickRegister">
              CREAR CUENTA PREMIUM
              <div>
                <div>$ <span class="amount">99</span> pesos al mes</div>
              </div>
            </a>
            <a class="navbar-item btn-login" @click="onClickLogin">
              <span>
                LOGIN
              </span>
              <span style="padding-left:10px">
                <BIcon icon="account-outline" size="is-normal"> </BIcon>
              </span>
            </a>
          </template>
        </div>
      </div>
    </nav>
    <div class="payment-alert" v-if="showPaymentAlert">
      <p>
        El cargo de tu plan mensual no pudo realizarse. Por favor revisa tu
        <a @click="$router.push({ name: 'payment-methods' })">método de pago</a>.
      </p>
      <strong class="close is-clickable" @click="showAlert = false">x</strong>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import { clickCrearCuentaPremium } from '@/utils/analytics';
import { BIcon } from 'buefy/dist/esm/icon';

export default {
  components: {
    BIcon,
  },
  props: {
    showMenuItems: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      home: '/tips',
      matches: '/matches',
      profile: '/profile',
      paymentMethods: '/profile/settings/payment-methods',
      contact: '/contact',
      showAlert: true,
      showMenu: false,
    };
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapGetters('loginGeneral', ['isLoggedIn', 'getPremiumAccount', 'getIsGolstats']),
    ...mapState('loginGeneral', ['user', 'pymErr']),
    ...mapGetters('general', ['getSectionAnalytics']),
    displayName() {
      if (this.user && this.user.name) {
        return this.user.name.length <= 9 ? this.user.name : this.user.name.substr(0, 7) + '...';
      }
      return '';
    },
    isMobile() {
      return this.width < 768;
    },
    currentPage() {
      return this.$route.path;
    },
    isHome() {
      return this.currentPage === this.home;
    },
    isMatches() {
      return this.currentPage.toLowerCase().includes(this.matches.toLowerCase());
    },
    isProfile() {
      return this.currentPage.toLowerCase().includes(this.profile.toLowerCase());
    },
    isContact() {
      return this.currentPage.toLowerCase().includes(this.contact.toLowerCase());
    },
    showPaymentAlert() {
      return this.showAlert && this.pymErr;
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newValue) {
        this.showMenu = newValue;
      },
    },
  },
  methods: {
    ...mapMutations(['SET_MENU_DRAWER']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapActions('nextGeneral', ['cleanStats']),
    ...mapMutations('general', ['setSectionAnalytics']),
    goTo(route) {
      let current = window.location.pathname;
      if (route !== current) this.$router.push(route);
      this.isActive = false;
    },
    onClickRegister() {
      this.setSectionAnalytics('Banner_Home');
      clickCrearCuentaPremium({
        is_premium: this.getPremiumAccount,
        section: 'menu',
        golstats: this.getIsGolstats,
        banner: this.getSectionAnalytics,
      });
      this.SET_AUTH_COMPONENT('SelectPlan');
    },
    onClickLogin() {
      this.SET_AUTH_COMPONENT('Login');
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-style {
  border: solid 1px;
  border-radius: 16px;
  padding: 5px 20px;
  margin: 10px;
  margin-left: auto;
  color: white;
}
.btn-style:hover {
  color: darkgrey;
}
.selected-page {
  border-bottom: unset;
}
img {
  padding-left: 20px;
}
.nav {
  &bar-start {
    width: 100%;
    justify-content: space-evenly;
  }
  &bar-item {
    font-family: 'Circular-Std-Bold';
  }
  &bar-end {
    padding-right: 40px;
    width: fit-content;

    .mobile {
      padding-right: 40px;
      border: none;

      .icon-menu {
        padding-left: 10px;
      }
      img {
        min-height: 2.4rem;
      }
      a {
        padding: 5px 20px;
        margin: 10px;
      }
    }
    a {
      border: solid 1px;
      border-radius: 16px;
      padding: 5px 20px;
      margin: 10px;
    }
  }
}
nav.navbar {
  align-items: center;
}
.mobile {
  color: white !important;
}
.navbar-burger {
  margin-left: auto;
}
.navbar-burger.burger {
  align-self: center;
  float: right;
}
@media screen and (min-width: 1024px) {
  .nav {
    &bar-start {
      width: 50%;
      justify-content: space-evenly;
    }
    &bar-menu {
      flex-shrink: 1 !important;
    }
  }
  .selected-page {
    border-bottom: solid 2px;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 1024px) {
  .menu-info {
    float: left;
    margin-top: -40px;
  }
  .icon-menu {
    min-height: 2.4px;
    margin-top: -40px;
  }
}

@media screen and (min-width: 1200px) {
  .navbar-start {
    width: 40%;
  }
  img {
    padding-left: 30px;
  }
}
.username {
  text-transform: capitalize;
}
.menu-info {
  text-transform: capitalize;
  color: whitesmoke !important;
}
.hamburger {
  margin-top: auto;
  margin-left: auto;
  margin-right: 20px;
  display: flex;
}
.user-name {
  white-space: nowrap;
}
.payment-alert {
  position: absolute;
  z-index: 5;
  width: 100%;
  background: #faa300;
  min-height: 35px;
  display: flex;
  align-items: center;
  color: white;
  p {
    margin: 5px 8px 5px 20px;
    a {
      color: white;
      text-decoration: underline white;
    }
  }
  .close {
    color: white !important;
    margin-left: auto;
    margin-right: 16px;
    font-size: 1.4rem;
  }
}
@media screen and (width: 768px) {
  // div.hamburger {
  //   & > .mobile {
  //     // position: absolute;
  //     // right: 0;
  //     // top: 4px;
  //     // background: blue !important;

  //     // .icon-menu {
  //     //   padding-left: 10px;
  //     //   background: red;
  //     // }
  //     // img {
  //     //   min-height: 2.4rem;
  //     // }
  //   }
  // }
  .payment-alert {
    margin-top: -12px !important;
  }
}
@media screen and (max-width: 767px) {
  .menu-info {
    float: left;
    margin-top: -40px;
  }
  .icon-menu {
    min-height: 2.4px;
    margin-top: -40px;
  }
  .payment-alert {
    margin-top: 8px !important;
  }
}
@media screen and (max-width: 600px) {
  div.hamburger {
    margin: 0;
    width: 100%;

    & > .mobile {
      position: absolute;
      right: 0;
      top: 4px;

      .menu-info {
        margin-top: 0px;
      }

      .icon-menu {
        padding-left: 10px;
        margin-top: 0px;
      }
    }

    & > .navbar-menu-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-left: 8px;

      & > a.navbar-item.btn-create-account.menu-button {
        height: fit-content;
        padding: 6px 10px;
        margin-right: 0;
        font-size: 16px;
        border-radius: 22px;

        & > div {
          font-size: 14px;
          top: unset;
          bottom: 6px;
          left: unset;
          right: -76px;
        }
      }
    }
  }
}
@media screen and (max-width: 390px) {
  div.hamburger {
    margin: 0;
    width: 100%;

    & > .navbar-menu-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      & > a.navbar-item.btn-create-account.menu-button {
        height: fit-content;
        padding: 6px 10px;
        margin-right: 0;
        font-size: 10px;
        border-radius: 22px;

        & > div {
          font-size: 11px;
          top: unset;
          bottom: 5px;
          left: unset;
          right: -68px;
        }
      }
    }
  }
}
.btn-create-account {
  position: relative;
  & > div {
    padding: 0.2rem 0.8rem 0;
    font-size: 0.9rem;
    background-image: linear-gradient(to right, #ae0b0b, #e02020);
    position: absolute;
    transform: skew(-25deg);
    top: -0.6rem;
    left: -7.5rem;
    box-shadow: 2px 2px 3px black;
    & > div {
      font-family: 'Avenir-Medium' !important;
      transform: skew(25deg);
      & > .amount {
        font-family: 'Avenir-Pro-Bold' !important;
        font-weight: bold !important;
      }
    }
  }
}

.navbar-burger.burger {
  position: relative;

  & > div.menu-text {
    position: absolute;
    font-family: Circular-Std, sans-serif;
    font-size: 12px;
    left: -32px;
    top: 17px;
  }
}
.navbar-menu-container {
  display: flex;
  align-items: center;
  & > a.navbar-item.btn-create-account.menu-button {
    height: fit-content;
    padding: 7px 20px;
    margin-right: 140px;
    color: white;
    border: solid 1px white;
    border-radius: 26px;

    & > div {
      top: unset;
      bottom: 8px;
      left: unset;
      right: -73px;
    }
  }
}
@media screen and (min-width: 1210px) {
  #test {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  #navbar-burger {
    display: block;
    border: 0;
  }
}
</style>
